'use client'

import { getCookie, setCookie } from "@/utils/cookie"
import { ReactNode, createContext, useCallback, useEffect, useState } from "react"

export type LanguageCode = "en" | "ko" | "vi" | "th" | "zh" | "ja";

export const supportedLanguages: LanguageCode[] = ['en', 'ko', "vi", "th", "zh", "ja"];

export interface Language {
    code: LanguageCode;
    name: string;
}

export const languages: Language[] = [
    { code: 'ko', name: "한국어" },
    { code: 'en', name: "English" },
    { code: 'vi', name: "Tiếng Việt" },
    { code: 'th', name: "ภาษาไทย" },
    { code: 'zh', name: "中文" },
    // { code: 'mn', name: "Монгол хэл" },
]

interface LanguageContextType {
    languageCode: LanguageCode;
    setLanguage: (code: LanguageCode) => void;
}


export const LanguageContext = createContext<LanguageContextType>({
    languageCode: 'ko',
    setLanguage: () => { },
});

export default function LanguageProvider({ children }: { children: ReactNode }) {
    const [languageCode, setLanguageCode] = useState<LanguageCode>(getDefaultLanguage)

    function getDefaultLanguage(): LanguageCode {
        const cookieLang = getCookie('language') as LanguageCode;
        return supportedLanguages.includes(cookieLang) ? cookieLang : 'ko';
    }

    const setLanguage = useCallback((code: LanguageCode) => {
        setCookie('language', code, { path: '/' })
        setLanguageCode(code)
    }, []);


    useEffect(() => {
        document.body.setAttribute('data-language', languageCode);
    }, [languageCode]);

    return (
        <LanguageContext.Provider value={{ languageCode, setLanguage }}>
            {children}
        </LanguageContext.Provider>
    )
}