"use client"

import api from "@/utils/api"
import { FormEvent, useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import { BANK_CHOICES } from "@/types/user";
import ErrorMessage from "../ErrorMessage/ErrorMessage";
import { useTranslation } from 'next-i18next';
import Image from "next/image";
import { siteMeta } from "@/siteConfig/settings/siteSetting";


export default function SignupModal({ show, setShow }: { show: boolean, setShow: Function }) {
    const [errors, setErrors] = useState<any>()
    const { t } = useTranslation();

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const formData = new FormData(e.currentTarget)
        setErrors(undefined)
        api(`/api/user/users/`, { method: "POST", body: formData })
            .then(res => Swal.fire({
                text: t("COMMON.APPLICATION_COMPLETE"),
                icon: 'success'
            }))
            .catch(res => res.json().then((data: any) => setErrors(data)))
    }

    function handleClose() {
        setShow(false)
        setTimeout(() => {
            setErrors(undefined)
        }, 300)
    }

    return (
        <Modal show={show}
            onHide={handleClose}
            size={'xl'}
            className="signup-modal"
            style={{ padding: 0 }}
        >
            <Modal.Body>
                <form onSubmit={handleSubmit}>
                    <div className="signup-modal-header">
                        <span className="site-name">회원가입</span>
                        <div className="close-window">
                            <FontAwesomeIcon icon={faXmark} onClick={handleClose} />
                        </div>
                    </div>
                    <div className="modal-form">
                        <div className="form-row">
                            <div className="content">
                                <div className="content-label">
                                    <label htmlFor="">{t("ACCOUNT.RECOMMENDER")}</label>
                                    {
                                        errors?.recommendation_code
                                        && errors.recommendation_code.map((message: string) =>
                                            <ErrorMessage key={message} message={message} />
                                        )
                                    }
                                </div>
                                <input type="text" name="recommendation_code" className={`case-unset ${errors?.recommendation_code && 'error'}`} required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="content">
                                <div className="content-label">
                                    <label htmlFor="">{t("ACCOUNT.ID")}</label>
                                    {
                                        errors?.username
                                        && errors.username.map((message: string) =>
                                            <ErrorMessage key={message} message={message} />
                                        )
                                    }
                                </div>
                                <input type="text" name="username" className={errors?.username && 'error'} required />

                            </div>
                        </div>
                        <div className="form-row">
                            <div className="content">
                                <div className="content-label">
                                    <label htmlFor="">{t("ACCOUNT.NICKNAME")}</label>
                                    {
                                        errors?.nickname
                                        && errors.nickname.map((message: string) =>
                                            <ErrorMessage key={message} message={message} />
                                        )
                                    }
                                </div>
                                <input type="text" name="nickname" className={errors?.nickname && 'error'} required />

                            </div>
                        </div>
                        <div className="form-row">
                            <div className="content">
                                <div className="content-label">
                                    <label htmlFor="">{t("ACCOUNT.PASSWORD")}</label>
                                    {
                                        errors?.new_password
                                        && errors.new_password.map((message: string) =>
                                            <ErrorMessage key={message} message={message} />
                                        )
                                    }
                                </div>
                                <input type="password" name="new_password" className={errors?.new_password && 'error'} required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="content">
                                <div className="content-label">
                                    <label htmlFor="">{t("ACCOUNT.PASSWORD_2")}</label>
                                    {
                                        errors?.new_password_confirm
                                        && errors.new_password_confirm.map((message: string) =>
                                            <ErrorMessage key={message} message={message} />
                                        )
                                    }
                                </div>
                                <input type="password" name="new_password_confirm" className={errors?.new_password_confirm && 'error'} required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="content">
                                <div className="content-label">
                                    <label htmlFor="">{t("ACCOUNT.PHONE_NUMBER")}</label>
                                    {
                                        errors?.phone
                                        && errors.phone.map((message: string) =>
                                            <ErrorMessage key={message} message={message} />
                                        )
                                    }
                                </div>
                                <input type="text" name="phone" className={errors?.phone && 'error'} required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="content">
                                <div className="content-label">
                                    <label htmlFor="">{t("WALLET.WITHDRAWAL_BANK")}</label>
                                    {
                                        errors?.bank_name
                                        && errors.bank_name.map((message: string) =>
                                            <ErrorMessage key={message} message={message} />
                                        )
                                    }
                                </div>
                                <select name="bank_name" className={errors?.bank_name && 'error'} required>
                                    <option value="">---</option>
                                    {BANK_CHOICES.map(([code, label]) => (
                                        <option key={code} value={code}>{label}</option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="content">
                                <div className="content-label">
                                    <label htmlFor="">{t("WALLET.WITHDRAWAL_ACCOUNT")}</label>
                                    {
                                        errors?.bank_number
                                        && errors.bank_number.map((message: string) =>
                                            <ErrorMessage key={message} message={message} />
                                        )
                                    }
                                </div>
                                <input type="text" name="bank_number" className={errors?.bank_number && 'error'} required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="content">
                                <div className="content-label">
                                    <label htmlFor="">{t("WALLET.WITHDRAWAL_PASSWORD")}</label>
                                    {
                                        errors?.bank_password
                                        && errors.bank_password.map((message: string) =>
                                            <ErrorMessage key={message} message={message} />
                                        )
                                    }
                                </div>
                                <input type="text" name="bank_password" className={errors?.bank_password && 'error'} required />
                            </div>
                        </div>
                        <div className="form-row">
                            <div className="content">
                                <div className="content-label">
                                    <label htmlFor="">{t("ACCOUNT.ACCOUNT_HOLDER")}</label>
                                    {
                                        errors?.bank_owner
                                        && errors.bank_owner.map((message: string) =>
                                            <ErrorMessage key={message} message={message} />
                                        )
                                    }
                                </div>
                                <input type="text" name="bank_owner" className={errors?.bank_owner && 'error'} required />
                            </div>
                        </div>
                    </div>
                    <div className="submit-box">
                        <button type="submit">{t("HEAD.SIGNUP")}</button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    )
}

