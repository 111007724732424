"use client";

import { createContext, useContext, useState, useEffect } from 'react'
import { useRouter } from 'next/navigation'

import { AuthContext } from './authContext'
import SigninModal from '@/components/Primitive/Modal/SigninModal'

export const SinginModalContext = createContext<{
    showModal: boolean,
    setSigninModalShow: (show: boolean) => void,
    UserAuthCheck: (callback: () => void) => void
}>({
    showModal: false,
    setSigninModalShow: () => { },
    UserAuthCheck: () => { },
})

export function SigninModalProvider({ children }: { children: any }) {
    const { user, loading } = useContext(AuthContext)
    const [showModal, setShowModal] = useState<boolean>(false)
    const router = useRouter()

    function SetShowModal(isShow: boolean) {
        setShowModal(isShow)
    }

    function UserAuthCheck(callback: () => void) {
        if (user.is_authenticated) {
            callback()
        } else {
            setShowModal(true)
        }
    }

    useEffect(() => {
        if (!loading && user?.is_authenticated == false) {
            router.push('/')
        }
    }, [user])

    return (
        <SinginModalContext.Provider value={{ UserAuthCheck, showModal, setSigninModalShow: SetShowModal }}>
            {children}
            <SigninModal show={showModal} setShow={setShowModal} />
        </SinginModalContext.Provider>
    )
}
