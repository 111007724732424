import { use, useCallback } from "react";

type SiteMetaType = { [key: string]: string }
type SiteSupportType = { [key: string]: boolean }
type SiteMenuType = { [key: string]: { name: string, url: string }[] }

export const siteMeta: SiteMetaType = {
    siteName: "BET1618",
    siteDescription: "테스트 카지노 유저 페이지"
};

export const siteLocale: string = "ko";
export const siteTimeZone: string = "Asia/Seoul";

export const siteSupport: SiteSupportType = {
    usePoint: true,
    useComp: true,
    useMultiLanguage: true,

    useSlot: true,
    useCasino: true,
    useSports: true,
    useMinigame: false,
    useHoldem: false,
};

export const siteMenu: SiteMenuType = {
    menu: [
        { name: "SLOT", url: "/slot" },
        { name: "CASINO", url: "/casino" },
        { name: "SPORTS", url: "/sports" },
        { name: "DEPOSIT", url: "/deposit" },
        { name: "DEPOSIT_HISTORY", url: "/deposit-history" },
        { name: "WITHDRAWAL", url: "/withdrawal" },
        { name: "WITHDRAWAL_HISTORY", url: "/withdrawal-history" },
        { name: "NOTICE", url: "/notice" },
        { name: "EVENT", url: "/event" },
        { name: "PASSWORD_CHANGE", url: "/password-change" },
        { name: "MESSAGE", url: "/message" },
        { name: "CUSTOMER_CENTER", url: "/customer-center" },
    ]
}
